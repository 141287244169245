import { prism, condensed } from "mdx-deck/themes";

export const customTheme = {
  // ...prism,
  ...condensed,
  googleFont: 'https://fonts.googleapis.com/css?family=Roboto',
  fonts: {
    body: "Valera , sans-serif",
    monospace: '"Roboto Mono", monospace',
  },
  styles: {
    // root: {
    //   textAlign: 'center',
    // },
    Slide: {
      display: 'block',
      padding: '1.5em 3em',
      fontSize: '170%',
    },
    ol: {
      textAlign: 'left',
      marginTop: '1em',
      paddingLeft: '3em',
    },
    ul: {
      textAlign: 'left'
    },
    pre: {
      textAlign: 'left'
    },
  },
};
